<template functional>
  <div
    class="
      flex-item-1
      s-common__header
      display-flex
      flex-wrap-wrap
      align-items-center
    "
    :class="$options.alignment_classes(props.align)"
  >
    <h2
      class="
        font-size-24
        bp-768:font-size-30
        line-height-125
        padding-b-6
        margin-r-auto
      "
    >
      {{ props.headline }}
    </h2>
    <div
      v-if="props.sponsor && props.sponsor.image"
      class="s-common__header--ad"
    >
      <a :href="props.sponsor.url" v-if="props.sponsor.url">
        <img :src="props.sponsor.image" :alt="props.headline + ' Sponsor'" />
      </a>
      <img
        v-if="!props.sponsor.url"
        :src="props.sponsor.image"
        :alt="props.headline + ' Sponsor'"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonHeader",
  props: {
    headline: {
      type: String,
      default: "",
    },
    sponsor: {
      type: Object,
      default: undefined,
    },
    align: {
      type: String,
      default: "left",
    },
  },
  alignment_classes(align) {
    let classes = "";
    switch (align) {
      case "left":
        classes += "justify-content-between";
        break;
      case "right":
        classes += "justify-content-end";
        break;
      default:
        classes += "";
    }

    return classes;
  },
};
</script>

<style scoped lang="scss">
.s-common {
  &__header {
    padding: 0;
    position: relative;
    box-shadow: inset 0px -1px 0px $main-border-color;
    h2 {
      margin: 0 auto 6px 0;
      color: $main-font-color;
    }
    &:after {
      content: "";
      display: block;
      background: var(--color-primary);
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      width: 120px;
    }
    &--ad {
      img {
        max-width: 150px;
      }
    }
    @media screen and (max-width: 768px) {
      padding: 0 !important;
      display: block !important;
      box-shadow: none !important;
      &:after {
        content: none !important;
      }
      h2 {
        margin: 0 !important;
        position: relative;
        text-align: center;
        padding: 9px 0;
        box-shadow: inset 0px -1px 0px var(--main-border-color);
        &:after {
          content: "";
          display: block;
          background: var(--color-primary);
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 3px;
          width: 60px;
        }
      }
      &--ad {
        text-align: center;
        max-width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
      }
    }
  }
}
</style>
