export default {
  props: {
    section: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    gameday() {
      if (this.$root.gameday) {
        return this.$root.gameday;
      } else return null;
    },
    template() {
      let _template = undefined;
      if (this.section.templates && this.section.templates.length) {
        _template = this.section.templates.find((template) => {
          return template.selected;
        });
      }
      return _template;
    },
  },
};
